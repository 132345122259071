import { createRef } from 'react';

import { NotFound } from "../app/errors/notFound/NotFound";
import { 
    CreateAccount,
    ForgotCredentials,
    ResetPassword,
    SignIn,
    SignOut,
    RevertIdentity
} from "../app/authentication";
import { SSOCallback } from '../app/authentication/ssoCallbacks/SSOCallback';
import { SupportSignIn } from '../app/intellek/SupportSignIn/SupportSignIn';

const routes = [
    {
        path: 'signin',
        nodeRef: createRef(),
        element: <SignIn />
    },
    {
        path: 'signout',
        nodeRef: createRef(),
        element: <SignOut />
    },
    {
        path: 'credentials/forgot',
        nodeRef: createRef(),
        element: <ForgotCredentials />
    },
    {
        path: 'account/create',
        nodeRef: createRef(),
        element: <CreateAccount></CreateAccount>
    },
    {
        path: 'credentials/reset',
        nodeRef: createRef(),
        element: <ResetPassword></ResetPassword>
    },    
    {
        path: 'identity/revert',
        element: <RevertIdentity />
    },
    {
        path: 'sso/adfs-callback',
        nodeRef: createRef(),
        element: <SSOCallback></SSOCallback>
    },
    {
        path: 'sso/okta-callback',
        nodeRef: createRef(),
        element: <SSOCallback></SSOCallback>
    },
    {
        path: 'sso/callback',
        nodeRef: createRef(),
        element: <SSOCallback></SSOCallback>
    },
    {
        path: 'support',
        nodeRef: createRef(),
        element: <SupportSignIn/>
    }, 
    {
        path: '*',
        element: <NotFound></NotFound>
    }
];

export default routes;