import { Navigate, RouteObject } from "react-router-dom";

import { MainLayout } from '../common/layouts/mainLayout/MainLayout';

// Routes from other areas
import adminRoutes from './admin.routes';
import authenticationRoutes from './authentication.routes';
import profileRoutes from './profile.routes';
import intellekRoutes from './intellek.routes';

// Components
import { NotFound } from "../app/errors/notFound/NotFound";
import homeRoutes from "./home.routes";

const filteredRoutes = intellekRoutes;

const filteredHomeRoutes = homeRoutes;

const routes: RouteObject[] = [
    {
        index: true,
        element: <Navigate to='/authentication/signin'/>,        
    },  
    {
        path: 'authentication',
        element: <MainLayout layoutType="external" />,
        children: authenticationRoutes
    },
    {
        path: 'profile',
        element: <MainLayout layoutType="application" />,
        children: profileRoutes
    },
    {
        path: 'admin',
        element: <MainLayout layoutType="application" />,
        children: adminRoutes
    },
    {
        path: 'intellek',
        element: <MainLayout layoutType="application" />,
        children: filteredRoutes
    },
    {
        path: '*',
        element: <NotFound></NotFound>
    }
];

export default routes;
