import { Text } from "../../../common/components";
import SignInUserAndPassword from "./signInUserAndPassword/SignInUserAndPassword";

import styles from "./SupportSignIn.module.scss";
import { SignInBannerMessage } from "./signInBannerMessage/SignInBannerMessage";

export function SupportSignIn() {
  return (
    <div className={styles["SignIn"]}>
      <header>
        <h1 className={styles["SignIn-heading1"]}>
          <Text
            fontStyle="headline-small"
            value="Intellek External Sign In"
          ></Text>
        </h1>
      </header>

      <SignInBannerMessage></SignInBannerMessage>

      <div className={styles["SignIn-content-container"]}>
        <div className={styles["SignIn-content"]}>
          <SignInUserAndPassword></SignInUserAndPassword>
        </div>
      </div>
    </div>
  );
}
